import React, { FC } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { withWidgetWCS } from '@wix/yoshi-flow-editor/wcs/wrappers';
import AppRoot from './App/AppRoot';
import { GroupPageWithStyles } from './App/types';
import { settingsParams } from '../../../common/settings/groups-list/settings';
import { stylesParams } from '../../../common/settings/groups-list/styles';
import { WidgetContext } from '../../../common/context/widget/WidgetContext';
import { WidgetType } from '../../../common/context/widget/WidgetType';

const Widget: FC<WidgetProps<GroupPageWithStyles>> = (props) => {
  return (
    <div data-hook="Groups-wrapper">
      <WidgetContext.Provider value={{ type: WidgetType.GroupsPage }}>
        <AppRoot {...props} />
      </WidgetContext.Provider>
    </div>
  );
};

export default Widget;
export const GroupsWCS = withWidgetWCS(Widget, settingsParams, stylesParams);
