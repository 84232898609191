import React from 'react';
import { PRIORITY } from 'wix-ui-tpa/Button';

import { Grid, GridProps } from 'wix-ui-tpa/Grid';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';

import { Button } from '../Button/Button';
import { DATA_HOOKS, GROUPS_LIST } from './dataHooks';
import { NoGroups } from './NoGroups';

import { classes, st } from './GroupList.st.css';
import { LayoutType } from '../../settings/groups-list/settingsConstants';
import { Spinner } from '../Spinner/Spinner';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import GroupsContext from '../../../components/Groups/contexts/GroupsContext';
import { GroupCard } from '../GroupCard/v2/GroupCard';
import { useGroupsSettings } from '../../hooks/settings/useGroupsSettings';
import GroupActionsContext from '../../../components/Groups/contexts/GroupsActionsContext';

const { PENDING } = ApiTypes.v1.GroupApprovalStatus;

export interface GroupListProps {
  onCreateGroupClick(): void;

  withCTA?: boolean;

  goToGroup?(groupId: string): void;

  mobile: boolean;
  className?: string;
}

type Props = GroupListProps;

export const GroupListComponent: React.FC<Props> = (props) => {
  const { goToGroup, mobile, onCreateGroupClick, withCTA, className } = props;
  const { t } = useTranslation();
  const { isViewer } = useEnvironment();
  const { groupNameQuery, groups, groupUrls } = React.useContext(GroupsContext);
  const { handleGroupCardCTA } = React.useContext(GroupActionsContext);
  const _settings = useGroupsSettings();

  if (!groups) {
    return <Spinner offset="L" label={t('groups-web.group-list.loading')} />;
  }

  const existingGroups =
    groups && groups.length
      ? groups.filter((group) => group.approvalStatus !== PENDING)
      : groups;

  if (!existingGroups.length) {
    if (groupNameQuery) {
      return (
        <NoGroups
          data-hook={DATA_HOOKS.noGroupsResult}
          emptyStateHeader={t('groups-web.search.no-results.title')}
          emptyStateText={t('groups-web.search.no-results.text')}
        />
      );
    }
    return (
      <NoGroups
        data-hook={DATA_HOOKS.noGroups}
        emptyStateHeader={t('groups-web.empty-state.header')}
        emptyStateText={t('groups-web.empty-state.text')}
        actionButton={
          withCTA ? (
            <Button
              priority={PRIORITY.primary}
              onClick={onCreateGroupClick}
              data-hook={DATA_HOOKS.noGroupsCreateButton}
            >
              {t('groups-web.empty-state.cta')}
            </Button>
          ) : undefined
        }
      />
    );
  }

  const onGoToGroup = (group: ApiTypes.v1.GroupResponse, event: any) => {
    // TODO: [BI] fix
    // tryToCallBi(async () => {
    //   await biLogger.groupsSelectGroupFromList({
    //     origin: event.target.dataset.biOrigin || 'group_block',
    //     groupId: group.groupId!,
    //     userEntry: BIUserEntry.SITE,
    //   });
    // });
    if (!event.target.href && group.slug && goToGroup) {
      goToGroup(group.slug);
    }
  };
  const {
    groupListLayout,
    gridCardSpacing,
    mobileGroupCardListSpacing,
    sideBySideCardSpacing,
  } = _settings;

  const listGrid = {
    maxColumns: 1,
    columnGap: 0,
    rowGap: mobile ? mobileGroupCardListSpacing : 0,
  };
  const gridProps: { [key: string]: Partial<GridProps> } = {
    [LayoutType.grid]: {
      maxColumns: mobile ? 1 : 3,
      columnGap: gridCardSpacing,
      rowGap: gridCardSpacing,
    },
    [LayoutType.list]: listGrid,
    [LayoutType.listWidget]: listGrid,
    [LayoutType.sideBySide]: {
      rowGap: sideBySideCardSpacing,
    },
  };
  return (
    <div data-hook={GROUPS_LIST}>
      <Grid
        {...gridProps[groupListLayout]}
        className={st(classes.grid, { layoutType: groupListLayout }, className)}
      >
        {existingGroups.map((group, i) => {
          const groupUrl = isViewer ? groupUrls![group.groupId!] : undefined;
          return (
            <Grid.Item key={group.groupId}>
              <GroupCard
                group={group}
                goToGroup={(event: any) => onGoToGroup(group, event)}
                groupUrl={groupUrl!}
                mobile={mobile}
                settings={_settings}
                handleGroupCardCTA={handleGroupCardCTA}
                even={i % 2 === 0}
              />
            </Grid.Item>
          );
        })}
      </Grid>
    </div>
  );
};

export const GroupList = GroupListComponent;
GroupList.displayName = 'GroupList';
