import React from 'react';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';

import { DATA_HOOKS } from './dataHooks';
import { GroupTypeSelect } from '../../../../../common/components/GroupTypeSelect/GroupTypeSelect';
import { Theme } from '../../../../../common/context/theme';
import { useExperiments } from '@wix/yoshi-flow-editor';

export interface GroupTypesProps extends Theme {
  selectedType: ApiTypes.v1.GroupPrivacyLevel;
  onSelect(option: any): void;
}

const translations = {
  [ApiTypes.v1.GroupPrivacyLevel.PUBLIC]: {
    value: 'groups-web.types.public.name',
    subtitle: 'groups-web.types.public.description',
  },
  [ApiTypes.v1.GroupPrivacyLevel.PRIVATE]: {
    value: 'groups-web.types.private.name',
    subtitle: 'groups-web.types.private.description',
  },
  [ApiTypes.v1.GroupPrivacyLevel.SECRET]: {
    value: 'groups-web.types.secret.name',
    subtitle: 'groups-web.types.secret.description',
  },
};

const translationsNew = {
  [ApiTypes.v1.GroupPrivacyLevel.PUBLIC]: {
    value: 'groups-web.types.public.name',
    subtitle: 'groups-web.types.public.description',
  },
  [ApiTypes.v1.GroupPrivacyLevel.PRIVATE]: {
    value: 'groups-web.types.private.name',
    subtitle: 'groups-web.types.private.description',
  },
};
export const GroupTypesDropdown: React.FC<GroupTypesProps> = (props) => {
  const { onSelect, selectedType, forceBlackAndWhite } = props;
  const { experiments } = useExperiments();
  return (
    <GroupTypeSelect
      forceBlackAndWhite={forceBlackAndWhite}
      onSelect={onSelect}
      selectedType={selectedType}
      translationKeys={
        experiments.enabled('specs.groups.NewJoinFlow')
          ? translationsNew
          : translations
      }
      dataHook={DATA_HOOKS.groupsTypeDropdown}
    />
  );
};
