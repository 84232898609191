import React from 'react';
import { PRIORITY } from 'wix-ui-tpa/Button';

import withGroups from '../../contexts/withGroups';

import withGroupsActions, {
  WithGroupsActionsProps,
} from '../../contexts/withGroupsActions';

import { classes, st } from './Groups.st.css';

import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import {
  canCreateGroup,
  canResolvePendingGroups,
} from '@wix/social-groups-api';

import { DATA_HOOKS } from './dataHooks';
import {
  withCurrentUser,
  WithCurrentUserProps,
} from '../../contexts/withCurrentUser';

import { WithGroupsProps } from '../../contexts/withGroupsProps';
import { GroupsSectionComponent } from './GroupsSection';

import { isApprovalStatusPending } from './IsApprovalStatusPending';
import { WithAppToastsProps } from '../../../../common/components/AppToats/withAppToastsProps';
import {
  WixSiteMember,
  WixSiteMemberActions,
} from '../../../../common/types/WixSiteMembers';
import {
  InjectedWebOutProps,
  withWebOut,
} from '../../../../common/components/WebOut';
import { MemberInvites } from '../../../../common/types/member-invites/MemberInvites';
import { getSettingsKeyFor } from '../../../../common/utils/utils';
import {
  AppToasts,
  withAppToasts,
} from '../../../../common/components/AppToats';
import { Button } from '../../../../common/components/Button/Button';
import { BIUserEntry } from '../../../../common/bi-logger/types';
import { compose } from '../../../../common/utils/compose';
import { withWixSiteMembers } from '../../../../common/context/members';
import { withMemberInvites } from '../../../../common/context/member-invites';

import { withTPAConfig } from '../../../../common/components/withTPAConfig';
import {
  InjectedBiLoggerProps,
  InjectedExperimentsProps,
  withBi,
  withExperiments,
  withTranslation,
} from '@wix/yoshi-flow-editor';
import { SearchSortingBox } from '../SearchSortingBox/SearchSortingBox';
import {
  withSettings,
  WithSettingsProps,
  withStyles,
  WithStylesProps,
} from '@wix/yoshi-flow-editor/tpa-settings/react';
import { settingsParams } from '../../../../common/settings/groups-list/settings';
import { stylesParams } from '../../../../common/settings/groups-list/styles';
import { groupsSearchForGroup } from '@wix/bi-logger-groups/v2';
import { PendingGroupList } from '../PendingGroupList/PendingGroupList';
import { ThemeProvider } from '../../../../common/context/theme';

export interface GroupsProps {}

export interface GroupsState {
  isCreateGroupModalOpened: boolean;
  isGroupCreating: boolean;
  isProfileDialogOpen: boolean;
}

export type ComponentProps = GroupsProps &
  WithCurrentUserProps &
  WithGroupsProps &
  WithGroupsActionsProps &
  WithAppToastsProps &
  WixSiteMember &
  WixSiteMemberActions &
  WithStylesProps &
  WithSettingsProps & { mobile: boolean } & InjectedWebOutProps &
  MemberInvites &
  InjectedExperimentsProps &
  InjectedBiLoggerProps;

export class GroupsComponent extends GroupsSectionComponent {
  static getDerivedStateFromProps(
    nextProps: ComponentProps,
    prevState: GroupsState,
  ): Partial<GroupsState> {
    const { isGroupCreating } = nextProps;
    const nextState: Partial<GroupsState> = {
      isGroupCreating,
    };
    if (isGroupCreating === false && prevState.isGroupCreating === true) {
      nextState.isCreateGroupModalOpened = false;
    }

    return nextState;
  }

  render() {
    const {
      actions,
      createGroupPolicy,
      currentUser,
      groupsSortBy,
      settings,
      groups,
      t,
      toasts,
      mobile,
      styles,
      groupSectionUrl,
      groupUrls,
      experiments,
    } = this.props;

    const withTitle = settings.get(
      settingsParams[getSettingsKeyFor('showHeaderTitle', mobile)],
    );
    const groupListTitle = settings.get(settingsParams.groupListTitle);
    const withSearchSortingBox = settings.get(
      settingsParams[getSettingsKeyFor('showSearchSorting', mobile)],
    );

    const shouldShowCreateGroupButton = canCreateGroup(
      currentUser,
      createGroupPolicy!,
    );

    const couldUserResolvePendingGroups = canResolvePendingGroups(currentUser);
    const shouldShowPendingGroupList =
      groups && groups.some(isApprovalStatusPending);

    const listTitleFont = styles.get(stylesParams.listTitleFont);
    const headerTag = listTitleFont.htmlTag;

    return (
      <ThemeProvider
        value={{
          forceBlackAndWhite: experiments.enabled(
            'specs.groups.ForceBlackAndWhite',
          ),
        }}
      >
        <div data-hook="groups" className={st(classes.root, { mobile })}>
          <AppToasts t={t} {...toasts} />
          <div className={classes.header}>
            <Text
              data-hook={DATA_HOOKS.groupListTitle}
              tagName={headerTag || 'h1'}
              typography={TYPOGRAPHY.largeTitle}
              className={st(classes.title, {
                headerAlignment: settings.get(settingsParams.headerAlignment),
              })}
            >
              {withTitle &&
                (groupListTitle == null
                  ? t('groups-web.settings.TextTab.groups.default')
                  : groupListTitle)}
            </Text>
            {/* TODO: refactor for widget (+ performance) */}
            {shouldShowCreateGroupButton && (
              <Button
                priority={PRIORITY.secondary}
                data-hook={DATA_HOOKS.createNewButton}
                onClick={this.handleCreateGroupClick}
                className={classes.createNewButton}
              >
                {t('groups-web.btn.create-new')}
              </Button>
            )}
          </div>
          {shouldShowPendingGroupList ? (
            <PendingGroupList
              data-hook={DATA_HOOKS.pendingGroupList}
              couldUserResolvePendingGroups={couldUserResolvePendingGroups}
              actions={actions}
              groups={groups}
              mobile={mobile}
              groupSectionUrl={groupSectionUrl}
              groupUrls={groupUrls}
            />
          ) : null}
          {withSearchSortingBox && (
            <SearchSortingBox
              onSearch={this.handleSearch}
              onSort={actions.sortGroups}
              initialSortSelectedId={groupsSortBy as string}
            />
          )}
          {super.render()}
        </div>
      </ThemeProvider>
    );
  }

  private readonly handleSearch = (value: string) => {
    const { actions, bi } = this.props;
    bi.report(
      groupsSearchForGroup({
        group_name: value,
        origin: 'top_panel_editbox',
        userEntry: BIUserEntry.SITE,
      }),
    );
    actions.searchGroups(value);
  };
}

const enhance = compose(
  withGroups,
  withCurrentUser,
  withGroupsActions,
  withAppToasts,
  withWixSiteMembers,
  withSettings,
  withStyles,
  withTPAConfig,
  withWebOut,
  withMemberInvites,
  withBi,
  withExperiments,
  withTranslation(),
);
export const Groups = enhance(
  GroupsComponent,
) as React.ComponentType<GroupsProps>;
